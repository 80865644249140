import React, { createContext, useState, useContext, useEffect } from 'react';
import restaverse from '../navigation/restaverse';
import { getAccessDetails } from '@src/views/restaverse/APIs/accountsettings';

const AccessControlContext = createContext();

export const AccessControlProvider = ({ children }) => {
  const [accessIds, setAccessIds] = useState([]);

  const updateAccessIds = async () => {
    try {
      const details = await getAccessDetails({ request: 'get' });
      setAccessIds(details); // Assuming details is an object or array containing accessIds
    } catch (error) {
      console.error('Error fetching access details:', error);
    }
  };

  // useEffect(() => {
  //   updateAccessIds();
  // }, []);

  // Render children with accessIds and updateAccessIds function
  return (
    <AccessControlContext.Provider value={{ accessIds, setAccessIds, updateAccessIds }}>
      {children}
    </AccessControlContext.Provider>
  );
};

export const useAccessControlContext = () => {
  return useContext(AccessControlContext);
};
