import React, { createContext, useState, useContext, useEffect } from 'react';
import restaverse from '../navigation/restaverse';
import { getMentionsUsers } from '../views/restaverse/APIs/reviews';

const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
    const [userData, setUserData] = useState({});

    const fetchUserData = async () => {
        const hideInternalUsers = process.env.REACT_APP_HIDE_INTERNAL_USERS === 'true';

        const payload = {};
        if (hideInternalUsers) {
            payload.hideInternalUsers = hideInternalUsers;
        }

        if (localStorage.getItem('email') != null) {
            try {
                const response = await getMentionsUsers(payload);
                setUserData(response);
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        }
    };

    useEffect(() => {
        console.log("Value changed: ", userData);
    }, [userData]);

    useEffect(() => {
        const initializeUserData = async () => {
            await fetchUserData();
        };
        initializeUserData();
    }, []);

    // Render children with userData and updateAccessIds function
    return (
        <UserContext.Provider value={userData}>
            {children}
        </UserContext.Provider>
    );
};

export const useUserContext = () => {
    return useContext(UserContext);
};
