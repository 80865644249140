import React, { createContext, useContext, useState } from 'react';

// Create a new context
const BrandDetailsContext = createContext(null);

// Provider component
export const BrandDetailsProvider = ({ children }) => {
  const [adsbrandDetails, setAdsBrandDetails] = useState(null);

  return (
    <BrandDetailsContext.Provider value={{ adsbrandDetails, setAdsBrandDetails }}>
      {children}
    </BrandDetailsContext.Provider>
  );
};

// Custom hook to access the context
export const useBrandDetails = () => {
  return useContext(BrandDetailsContext);
};
