// **  Initial State
const initialState = {
  userData: {
    loggingOut: false
  }
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        userData: {...action.data, ...{loggingOut: false}},
        [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
        [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
      }
    case "LOGGING_OUT":
      return {...{userData: action.data}}
    case 'LOGOUT':
      const obj = { ...action }
      delete obj.type
      return { ...state, userData: {loggingOut: false}, ...obj }
    default:
      return state
  }
}

export default authReducer
