// ** Logo
// import logo from '@src/assets/images/logo/logo.png'
import {FcComboChart} from 'react-icons/fc'
import themeConfig from '@configs/themeConfig'

const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner vh-100'>
      {/* <img className='fallback-logo' src={logo} alt='logo' /> */}
      <div className='fallback-logo'>
        {/* <span className='brand-logo'>
          <FcComboChart size={32}/>
        </span> */}
        <h1 className='brand-text mb-0 text-center' style={{ fontFamily: "'BC Novatica CYR Regular', sans-serif"}}>{themeConfig.app.appName}</h1>
      </div>

      <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
